<template>
    <div class="relative text-white-1">
        <div class="w-full absolute block z-20">
            <Header/>
        </div>
        <div class="space-top pn-safety-banner-container w-full">
            <div class="relative h-full w-full flex justify-center items-start flex-col">
                <img class="absolute top-0 -right-8 mobileL:-right-28 xl:-right-20 2xl:-right-28 h-full object-cover z-5 mobileL:pt-12 xl:pt-16" src="../../../assets/images/safety-standards/pn-safety-banner.png" alt="Contact Us Banner">
                <div class="flex flex-col mx-12 mobileL:mx-0 text-left mobileL:text-center z-10 max-w-lg lg:max-w-lg xl:max-w-xl 2xl:max-w-3xl p-0 mobileL:p-4
                ml-6 mobileL:ml-12 lg:ml-16 xl:ml-24 2xl:ml-48 relative -mt-2 mobileL:-mt-12">
                    <div class="relative inline-block">
                        <span class="pn-safety-bg-highlight absolute top-4 mobileL:top-6 lg:top-8 2xl:top-12 left-0 w-full h-1/2 2xl:h-1/2 z-10"></span>
                        <img class="absolute -top-1 -left-1 w-3 lg:w-4 z-0" src="../../../assets/images/home-page/shine-sm.png" alt="">
                        <div class="text-4xl md:text-4xl lg:text-5xl xl:text-6xl 2xl:text-7xl font-semibold relative z-20">
                            <h1 class="font-kannada font-semibold">Safety Standards</h1>
                        </div>
                        <img class="absolute top-0 xl:-top-8 hidden mobileL:block -right-12 xl:-right-20 z-0 xl:w-28 2xl:w-auto w-20" src="../../../assets/images/home-page/sketch-line.png" alt="">
                    </div>
                </div>
                <img class="absolute hidden mobileL:block top-12 left-12 lg:top-24 lg:left-24 object-cover z-5 pt-4 w-20 lg:w-28 xl:w-32" src="../../../assets/images/contact-us/pn-leaf.png" alt="leaf">
                <img class="absolute left-8 bottom-6 mobileL:bottom-16 mobileL:left-40 lg:bottom-24 lg:left-56 xl:left-96 2xl:left-110 object-cover z-5 pt-4 w-10 mobileL:w-24 lg:w-28 xl:w-32 2xl:w-36" src="../../../assets/images/contact-us/pn-leaf-2.png" alt="leaf">
            </div>
        </div>
        <div class="relative">
            <img class="absolute inset-0 w-full h-full object-cover" src="../../../assets/images/safety-standards/pn-body-banner.png" alt="Background Image">
            <div class="relative top-0 left-0 w-full h-full flex items-center justify-center flex-col py-4 lg:py-8">
                <div class="text-white-1 font-kannada mobileL:font-monsterrat md:text-xl lg:text-3xl 2xl:text:5xl w-3/5 text-center my-4 mobileL:my-8 leading-tight">
                    <p class="text-lg lg:text-xl xl:text-2xl 2xl:text-4xl leading-tight font-kannada mobileL:font-mermaid font-semibold mb-6 2xl:mb-8 -mx-12 mobileL:mx-0">
                        As your safety remains our utmost priority, we highly encourage 
                        you to book an appointment via call or text before heading to 
                        our branches.
                    </p>
                    <p class="text-base lg:text-lg xl:text-xl 2xl:text-3xl leading-tight font-monsterrat -mx-12 2xl:-mx-16">
                        We've also implemented new store protocols to ensure the safety 
                        and protection of all, please be advised as we take care of you 
                        and your hair-removal needs.
                    </p>
                </div>
            </div>
        </div>
        <div class="relative pn-safety-body-bg px-8 lg:px-16">
            <div class="relative text-white-1">
                <div class="relative flex flex-col mobileL:flex-row-reverse items-center justify-center gap-1 mobileL:gap-12">
                    <img class="mobileL:mr-4 w-1/2 mobileL:w-1/5 my-4 mobileL:my-12" src="../../../assets/images/safety-standards/pn-time.png" alt="">   
                    <div class="w-4/6 text-center mobileL:text-left font-kannada mobileL:font-century">
                        <h2 class="text-2xl mobileL:text-3xl lg:text-4xl 2xl:text-5xl font-semibold mb-2 mobileL:mb-4 font-kannada mobileL:font-mermaid">BE ON TIME</h2>
                        <p class="text-lg mobileL:text-base lg:text-xl xl:text-2xl 2xl:text-3xl leading-tight mobileL:leading-normal -mx-8 mobileL:mx-0">
                            To avoid cancellation of your appointment, please arrive at least 15 minutes before your schedule
                        </p>
                    </div>
                </div>
                <div class="relative flex flex-col mobileL:flex-row items-center justify-center gap-1 mobileL:gap-12">
                    <img class="mobileL:mr-4 w-1/2 mobileL:w-1/5 my-4 mobileL:my-12" src="../../../assets/images/safety-standards/pn-distance.png" alt="">
                    <div class="w-4/6 text-center mobileL:text-right font-kannada mobileL:font-century">
                        <h2 class="text-2xl mobileL:text-3xl lg:text-4xl 2xl:text-5xl font-semibold mb-2 mobileL:mb-4 font-kannada mobileL:font-mermaid -mx-16 mobileL:mx-0">
                            KEEP A SAFE DISTANCE
                        </h2>
                        <p class="text-lg mobileL:text-base lg:text-xl xl:text-2xl 2xl:text-3xl leading-tight mobileL:leading-normal -mx-8 mobileL:mx-0">
                            To maintain proper social distancing, we're imposing a strict 
                            "no companion" policy in our salons. 
                            A maximun of 2 people are allowed to stay in our reception areas.
                        </p>
                    </div>
                </div>
                <div class="relative flex flex-col mobileL:flex-row-reverse items-center justify-center gap-1 mobileL:gap-12">   
                    <img class="mobileL:mr-4 w-1/2 mobileL:w-1/5 my-4 mobileL:my-12" src="../../../assets/images/safety-standards/pn-client.png" alt="">
                    <div class="w-4/6 text-center mobileL:text-left font-kannada mobileL:font-century">
                        <h2 class="text-2xl mobileL:text-3xl lg:text-4xl 2xl:text-5xl font-semibold mb-2 mobileL:mb-4 font-kannada mobileL:font-mermaid -mx-12 mobileL:mx-0">
                            CLIENT DECLARATION
                        </h2>
                        <p class="text-lg mobileL:text-base lg:text-xl xl:text-2xl 2xl:text-3xl leading-tight mobileL:leading-normal -mx-8 mobileL:mx-0">
                            We'll also be asking our guest to declare medical and travel 
                            history and we'll be doing a temperature check too 
                            just to be extra sure! If clients are indicative of any symptoms 
                            or fail the temperature check, 
                            they will not be permitted to enter our salons.
                        </p>
                    </div>
                </div>
                <div class="relative flex flex-col mobileL:flex-row items-center justify-center gap-1 mobileL:gap-12">
                    <img class="mobileL:mr-4 w-1/2 mobileL:w-1/5 my-4 mobileL:my-12" src="../../../assets/images/safety-standards/pn-mask.png" alt="">
                    <div class="w-4/6 text-center mobileL:text-right font-kannada mobileL:font-century">
                        <h2 class="text-2xl mobileL:text-3xl lg:text-4xl 2xl:text-5xl font-semibold mb-2 mobileL:mb-4 font-kannada mobileL:font-mermaid -mx-12 mobileL:mx-0">
                            NO MASK NO ENTRY
                        </h2>
                        <p class="text-lg mobileL:text-base lg:text-xl xl:text-2xl 2xl:text-3xl leading-tight mobileL:leading-normal -mx-8 mobileL:mx-0">
                            Clients are required to wear face masks while in our store premises.
                        </p>
                    </div>
                </div>
                <div class="relative flex flex-col mobileL:flex-row-reverse items-center justify-center gap-1 mobileL:gap-12">
                    <img class="mobileL:mr-4 w-1/2 mobileL:w-1/5 my-4 mobileL:my-12" src="../../../assets/images/safety-standards/pn-sanitation.png" alt="">   
                    <div class="w-4/6 text-center mobileL:text-left font-kannada mobileL:font-century">
                        <h2 class="text-2xl mobileL:text-3xl lg:text-4xl 2xl:text-5xl font-semibold mb-2 mobileL:mb-4 font-kannada mobileL:font-mermaid -mx-12 mobileL:mx-0">
                            STRICT SANITATION
                        </h2>
                        <p class="text-lg mobileL:text-base lg:text-xl xl:text-2xl 2xl:text-3xl leading-tight mobileL:leading-normal -mx-8 mobileL:mx-0">
                            Of course, we will be implementing strict hygiene 
                            standards when it comes to the sanition and 
                            cleaning practices of our stores to make it virus-free and extra safe.
                        </p>
                    </div>
                </div>
                <div class="relative flex flex-col mobileL:flex-row items-center justify-center gap-1 mobileL:gap-12">
                    <img class="mobileL:mr-4 w-1/2 mobileL:w-1/5 my-4 mobileL:my-12" src="../../../assets/images/safety-standards/pn-payment.png" alt="">
                    <div class="w-4/6 text-center mobileL:text-right font-kannada mobileL:font-century">
                        <h2 class="text-2xl mobileL:text-3xl lg:text-4xl 2xl:text-5xl font-semibold mb-2 mobileL:mb-4 font-kannada mobileL:font-mermaid -mx-8 mobileL:mx-0">
                            CASHLESS PAYMENT ARE WELCOME
                        </h2>
                        <p class="text-lg mobileL:text-base lg:text-xl xl:text-2xl 2xl:text-3xl leading-tight mobileL:leading-normal -mx-8 mobileL:mx-0">
                            We've also equipped some of our branches to access cashless 
                            payments to lessen contact and make the experience more hassle-free.
                        </p>
                    </div>
                </div>
                <div class="relative flex flex-col mobileL:flex-row-reverse items-center justify-center gap-1 mobileL:gap-12 pb-4 mobileL:pb-8">   
                    <img class="mobileL:mr-4 w-1/2 mobileL:w-1/5 my-4 mobileL:my-12" src="../../../assets/images/safety-standards/pn-staff.png" alt="">
                    <div class="w-4/6 text-center mobileL:text-left font-kannada mobileL:font-century">
                        <h2 class="text-2xl mobileL:text-3xl lg:text-4xl 2xl:text-5xl font-semibold mb-2 mobileL:mb-4 font-kannada mobileL:font-mermaid -mx-8 mobileL:mx-0">
                            OUR STAFF IS READY TO SERVE YOU
                        </h2>
                        <p class="text-lg mobileL:text-base lg:text-xl xl:text-2xl 2xl:text-3xl  leading-tight mobileL:leading-normal -mx-8 mobileL:mx-0">
                            They will be in PPEs, complete with face masks, shields, aprons, 
                            gloves to make sure they're more than ready to provide the 
                            expert care to our clients. FYI, we are also operating 
                            with minimal staffing so please bear with us.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <div class="w-full absolute block z-20">
            <Footer/>
        </div>
  </div>
</template>

<script>
import Header from '../../../components/navigation/LaybareHeader.vue'
import Footer from '../../../components/footer/PassionailsFooter.vue'

export default {
    components: {
        Header,
        Footer,
    },
    mounted() {
        this.$gtag.pageview('/passionails/safety-standards')
    },
}
</script>

<style>
.pn-safety-banner-container {
    height: 700px;
    background-size: cover;
    background: linear-gradient(180deg, #dd9d9e 0%, #dd9d9e 100%);
}

@media screen and (min-width: 640px) and (max-width: 1023px) {
    .pn-safety-banner-container {
        height: 45dvh;
    }
}

.space-top {
  margin-top: 90px;
}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
    }
}

@media (min-width: 1536px) {
    .pn-safety-banner-container {
      height: 110vh;
    }
    .space-top {
      margin-top: 128px;
    }
}

@media (max-width:480px) {
    .pn-safety-banner-container{
        height:255px;
        background: #F0ACBB;
    }
    .space-top {
    margin-top: 60px;
    }
}

.pn-safety-banner-title {
    color: #FFF;
    font-style: normal;
}

.pn-safety-bg-highlight {
    position: absolute;
    background: #7D4979;
    left: 0px;
}

.pn-safety-body-bg {
    background: linear-gradient(225deg, #a271a0, #d3a3b3);
}

</style>